import { LoadingEntityInfo, PrefetchedEntityInfo } from './EntityInfo'
import { type InfoFetcherProps } from './types'
import { useUser } from '@front/model'
import { Text } from '@mantine/core'
import { Link } from '@remix-run/react'
import { getInitials, getName, isArchivedUser, TinyUser, type WithUserId } from '@terros/common'
import { type ReactElement, type ReactNode } from 'react'

type UserInfoProps = InfoFetcherProps & WithUserId

export const UserInfo = ({ userId, linked, ...avatarProps }: UserInfoProps): ReactNode => {
  const { data, isLoading, error } = useUser(userId)
  if (error) return <Text className='text-red-400'>{error}</Text>
  if (isLoading || !data) return <LoadingEntityInfo {...avatarProps} />

  return <PrefetchedUserInfo {...avatarProps} linked={linked} user={data} />
}

type UserInfoPrefetchedInput = InfoFetcherProps & {
  user: TinyUser
  companyName?: string
}

export const PrefetchedUserInfo = (props: UserInfoPrefetchedInput): ReactElement => {
  const { user, linked = true, ...rest } = props

  const entityInfo = {
    ...rest,
    ...user,
    name: getName(user),
    initials: getInitials(user),
  }
  if (linked && !isArchivedUser(user.userId)) {
    return (
      <Link to={`/user/${user.userId}`}>
        <PrefetchedEntityInfo {...entityInfo} />
      </Link>
    )
  }

  return <PrefetchedEntityInfo {...entityInfo} />
}
